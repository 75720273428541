define("discourse/plugins/discourse-topic-bump/discourse/templates/modal/topic-bump", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    <DModalBody @rawTitle={{ this.title }}>
  
      <p>
        {{#if this.next_autobump}}
          {{{i18n "topic_bump.auto_bump.modal_text_enabled"}}}
        {{else}}
          {{{i18n "topic_bump.auto_bump.modal_text_disabled"}}}
      {{/if}}
      </p>
  
    </DModalBody>
  
    <div class="modal-footer">
      {{#if this.next_autobump}}
      <DButton
        @action={{action "disableAutobump"}}
        class="btn-primary"
        @label="topic_bump.auto_bump.modal_button_disable"
      />
      {{else}}
      <DButton
        @action={{action "enableAutobump"}}
        class="btn-primary"
        @label="topic_bump.auto_bump.modal_button_enable"
      />
      <DButton
        @action={{action "bumpOnce"}}
        class="btn-primary"
        @label="topic_bump.auto_bump.modal_button_bump"
      />
      {{/if}}
      <DButton
        @action={{action "close"}}
        @label="topic_bump.auto_bump.modal_button_cancel"
      />
    </div>
  </div>
  
  */
  {
    "id": "Eua++rIy",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@rawTitle\"],[[30,0,[\"title\"]]]],[[\"default\"],[[[[1,\"\\n\\n    \"],[10,2],[12],[1,\"\\n\"],[41,[30,0,[\"next_autobump\"]],[[[1,\"        \"],[2,[28,[37,2],[\"topic_bump.auto_bump.modal_text_enabled\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[2,[28,[37,2],[\"topic_bump.auto_bump.modal_text_disabled\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n\"],[41,[30,0,[\"next_autobump\"]],[[[1,\"    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,4],[[30,0],\"disableAutobump\"],null],\"topic_bump.auto_bump.modal_button_disable\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,4],[[30,0],\"enableAutobump\"],null],\"topic_bump.auto_bump.modal_button_enable\"]],null],[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,4],[[30,0],\"bumpOnce\"],null],\"topic_bump.auto_bump.modal_button_bump\"]],null],[1,\"\\n\"]],[]]],[1,\"    \"],[8,[39,3],null,[[\"@action\",\"@label\"],[[28,[37,4],[[30,0],\"close\"],null],\"topic_bump.auto_bump.modal_button_cancel\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"d-modal-body\",\"if\",\"i18n\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-topic-bump/discourse/templates/modal/topic-bump.hbs",
    "isStrictMode": false
  });
});