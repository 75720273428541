define("discourse/plugins/discourse-topic-bump/discourse/pre-initializers/extend-category-for-topic-bump", ["exports", "discourse/models/category", "@ember/object"], function (_exports, _category, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-category-for-topic-bump",
    before: "inject-discourse-objects",
    initialize() {
      _category.default.reopen({
        allow_topic_bump: (0, _object.computed)("custom_fields.allow_topic_bump", {
          get(fieldName) {
            return (0, _object.get)(this.custom_fields, fieldName) === "true";
          }
        })
      });
    }
  };
});