define("discourse/plugins/discourse-topic-bump/discourse/templates/connectors/category-custom-settings/topic-bump-settings", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if siteSettings.topic_bump_enabled}}
    <h3>{{i18n "topic_bump.title"}}</h3>
    <section class="field">
      <div class="allow-topic-bump">
        <label class="checkbox-label">
          {{input
            type="checkbox"
            checked=category.custom_fields.allow_topic_bump
          }}
          {{i18n "topic_bump.allow_topic_bump"}}
        </label>
      </div>
    </section>
  {{/if}}
  */
  {
    "id": "VkFHnt/O",
    "block": "[[[41,[30,0,[\"siteSettings\",\"topic_bump_enabled\"]],[[[1,\"  \"],[10,\"h3\"],[12],[1,[28,[35,1],[\"topic_bump.title\"],null]],[13],[1,\"\\n  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"allow-topic-bump\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"allow_topic_bump\"]]]]]],[1,\"\\n        \"],[1,[28,[35,1],[\"topic_bump.allow_topic_bump\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-topic-bump/discourse/templates/connectors/category-custom-settings/topic-bump-settings.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `category` property path was used in the `discourse/plugins/discourse-topic-bump/discourse/templates/connectors/category-custom-settings/topic-bump-settings.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.category}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"i18n\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-topic-bump/discourse/templates/connectors/category-custom-settings/topic-bump-settings.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});