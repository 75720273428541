define("discourse/plugins/discourse-topic-bump/discourse/controllers/topic-bump", ["exports", "@ember/controller", "I18n", "discourse/lib/ajax", "discourse/mixins/modal-functionality", "@ember/object", "@ember/service"], function (_exports, _controller, _I18n, _ajax, _modalFunctionality, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  async function performAction(action, topic, dlg) {
    try {
      let result = await (0, _ajax.ajax)(`/t/${topic.id}/${action}`, {
        type: "POST"
      }).then(data => {
        if (data.msg) {
          dlg.alert(_I18n.default.t("topic_bump.auto_bump.performed_" + action));
        }
      });
    } catch (e) {
      const xhr = e.xhr || e.jqXHR;
      if (xhr && xhr.responseJSON) {
        if (xhr.responseJSON.error_type == 'rate_limit') {
          dlg.alert(_I18n.default.t("topic_bump.bump_topic_button.rate_limit_error"));
        }
        if (xhr.responseJSON.error_type == 'invalid_access') {
          dlg.alert(_I18n.default.t("topic_bump.bump_topic_button.error"));
        }
      }
      dlg.alert(_I18n.default.t("topic_bump.bump_topic_button.error"));
    }
  }
  let TopicBumpController = _exports.default = (_class = class TopicBumpController extends _controller.default.extend(_modalFunctionality.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialog", _descriptor, this);
    }
    onShow() {
      this.set("title", this.model.topic.next_autobump ? _I18n.default.t("topic_bump.auto_bump.modal_title_enabled") : _I18n.default.t("topic_bump.auto_bump.modal_title_disabled"));
      this.set("next_autobump", this.model.topic.next_autobump);
    }
    enableAutobump() {
      performAction("autobump_enable", this.model.topic, this.dialog);
      this.model.topic.next_autobump = 1;
      this.send("closeModal");
    }
    disableAutobump() {
      performAction("autobump_disable", this.model.topic, this.dialog);
      this.model.topic.next_autobump = false;
      this.send("closeModal");
    }
    bumpOnce() {
      performAction("bump", this.model.topic, this.dialog);
      this.send("closeModal");
    }
    close() {
      this.send("closeModal");
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "enableAutobump", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "enableAutobump"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableAutobump", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "disableAutobump"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bumpOnce", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "bumpOnce"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class);
});